<template>
  <div class="text-right box iosClass">
    <div class="dflexs  head">
      <img class="returns" @click='returns' src="../assets/level/return.png" alt="">
      <div class="pagetitle">{{$t('patricianDescriptiontitle')}}</div>
    </div>
    <div class="text beijin">
      <p>{{ $t("nobilityLevel.txt1") }}</p>
      <p>{{ $t("nobilityLevel.txt2") }}</p>
      <p>{{ $t("nobilityLevel.txt3") }}</p>
      <!-- <p>{{ $t("nobilityLevel.txt4") }}</p>
      <p>{{ $t("nobilityLevel.txt5") }}</p>
      <p>{{ $t("nobilityLevel.txt6") }}</p> -->
    </div>
    <table>
  <tr>
    <th>{{ $t("nobilityLevel.txt7") }}</th>
    <th>{{ $t("nobilityLevel.txt8") }}</th>
    <th>{{ $t("nobilityLevel.txt9") }}</th>
  </tr>
  <tr>
    <td>VIP1</td>
    <td>100000</td>
    <td>100000</td>
  </tr>
  <tr>
    <td>VIP2</td>
    <td>200000</td>
    <td>100000</td>
  </tr>
  <tr>
    <td>VIP3</td>
    <td>400000</td>
    <td>200000</td>
  </tr>
  <tr>
    <td>VIP4</td>
    <td>800000</td>
    <td>400000</td>
  </tr>
  <tr>
    <td>VIP5</td>
    <td>2000000</td>
    <td>1200000</td>
  </tr>
  <tr>
    <td>VIP6</td>
    <td>3000000</td>
    <td>1000000</td>
  </tr>
  <tr>
    <td>VIP7</td>
    <td>4000000</td>
    <td>1000000</td>
  </tr>
  <tr>
    <td>VIP8</td>
    <td>5000000</td>
    <td>1000000</td>
  </tr>
  <tr>
    <td>VIP9</td>
    <td>10000000</td>
    <td>5000000</td>
  </tr>
  <tr>
    <td>VIP10</td>
    <td>20000000</td>
    <td>10000000</td>
  </tr>
</table>
    <!-- <table border="1" cellspacing="0">
      <tr>
        <td align="center" colspan="2" height="30" class="title2">
          {{ $t("nobilityLevel.head") }}
        </td>
      </tr>
      <tr>
        <td align="center" class="title2">{{ $t("Level.title") }}</td>
        <td align="center" class="title2">{{ $t("nobilityLevel.diamond") }}</td>
      </tr>
      <tr>
        <td align="center">Lv.1</td>
        <td align="center title3" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >600,00</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >199,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.2</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >200,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >399,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.3</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >400,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.4</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >1,000,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >1,999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.5</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >2,000,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >4,999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.6</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >5,000,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >7,999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.7</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >8,000,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >9,999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >10,000,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >19,999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.9</td>
        <td align="center" style="display: flex;justify-content: center;">
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >20,000,000</span
            >
          </div>
          —
          <div class="mox-box">
            <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
              >49,999,999</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">Lv.10</td>
        <td align="center" style="display: flex;justify-content: center;">
          <img width="20px" src="../assets/layla_zuan.png" alt="" /><span
            >50,000,000以上</span
          >
        </td>
      </tr>
    </table> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: [],
      client:''
    };
  },
  created() {
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    this.judgeClient()
  },
  mounted() {
    //页面css初始化
    document.getElementsByTagName("html")[0].style.backgroundColor = "#F5F6F8";
    document.getElementsByTagName("html")[0].style.padding = "0";
  },
  methods: {
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
        //返回
    returns() {
      this.$completes.complete("getPreviousPage");
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      let langs = this.userInfo.lang.substring(0, 2);
      let lang = this.userInfo.lang;
      if (langs == "zh") {
        if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
        } else {
          this.$i18n.locale = "zh";
        }
      } else if (langs == "en") {
        this.$i18n.locale = "en";
      } else if (langs == "ar") {
        this.$i18n.locale = "ar";
      } else if (langs == "tr") {
        this.$i18n.locale = "tr";
      } else if (langs == "es") {
        this.$i18n.locale = "es";
      } else if (langs == "pt") {
        this.$i18n.locale = "pt";
      } else {
        this.$i18n.locale = "en";
      }
      document.title = this.$t("nobilityLevel.title");
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

html[lang="ar"] .returns {
  -webkit-transform: rotateY(180deg);
}
html[lang="ar"] .head {
  direction: rtl;
}
html[lang="ar"] .text p {
  direction: rtl;
}
html[lang="ar"] .title2 {
  direction: rtl;
}
html[lang="ar"] .pagetitle {
  margin-left: 0.4rem;
  margin-right: 0;
}

.box {
  font-size: 26px;
  font-weight: 400;
  color: #000;
  background: #F5F6F8;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
}

.head {
  display: flex;
  align-items: center;
  background: #F5F6F8;
  justify-content: center;
  padding: 0 0.27rem;
  height: 44px;
  margin: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  padding-top: 44px;
}

.returns {
  width: 0.5rem;
  height: 0.5rem;
}

.pagetitle {
  font-size: 0.34rem;
  font-family: Montserrat;
  color: #000000;
  line-height: 0.5rem;
  flex: 1;
  text-align: center;
  margin-right: 0.4rem;
  font-weight: 700;
}


.text {
  flex: 1;
  padding: 90px  28px 20px 28px;
}

.text p {
  line-height: 44px;
  margin-top: 20px;
}
table {
  margin: 0 18px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}
.mox-box {
  width: 160px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
table .mox-box:nth-of-type(1){
  /* justify-content: flex-end; */
}
.iosClass .head{
  padding-top: 0.88rem;
  padding-bottom: 0.2rem;
}
.iosClass .huadongg{
  /* padding-top: 0.44rem; */
}
.iosClass .beijin{
  padding-top: 1.4rem;
}
.iosClass .van-swipe{
  padding-bottom: 0.44rem;
}
table td{
  align-items: center;
}
table {
    width: 96%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: #f2f2f2;
  }
</style>
